html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}


.select{
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}
.select::after{
  content: "";
  width: 10px;
  height: 6px;
  background: url(/img/svg/arrow-down.svg) no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 15px;
  transition-duration: 0.1s;
}
.select__value{
  position: relative;
  user-select: none;
  margin-top: 5px;
  background: #ffffff11;
  border-radius: 5px;
  border: none;
  color: #fff;
  outline: none;
  padding: 15px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  transition-duration: 0.2s;
  cursor: pointer;
  z-index: 4;
}
.select__list{
  position: absolute;
  top: 5px;
  background: #fff;
  width: 100%;
  padding: 15px;
  padding-top: 38px;
  padding-bottom: 9px;
  overflow: auto;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  //row-gap: 12px;
  font-size: 14px;
  transition-duration: 0.2s;
  transform-origin: top;
  transform: scaleY(0.3);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
}
.select__list_open{
  transform: scaleY(1);
  opacity: 1;
  pointer-events: all;
}
.select:has(.select__list_open) .select__value{
  color: var(--darkpurple);
}
.select:has(.select__list_open)::after{
  filter: invert(1);
  transform: scaleY(-1);
}
.select__option{
  //padding: 5px 10px;
  padding: 6px 0;
  cursor: pointer;
}
.select__option:hover{
  color: var(--purple);
}