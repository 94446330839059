// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "CodecCold";
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --white: #fff;
  --purple: #252160;
  --darkpurple: #0d0f38;
  --gray: #bbcbd4;
  --orange: #f79321;
  --darkorange: #d97427;

}
