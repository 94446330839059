

.modal{

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #00000088;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition-duration: 0.3s;

    &_open{
      z-index: 99;
      pointer-events: all;
      opacity: 1;
    }

    &__inner{
      background: #fff;
      width: 100%;
      max-width: 470px;
      padding: 60px 30px;
      border-radius: 10px;
      position: relative;
    }

    &__title{
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 5px;
    }

    &__text{
      font-size: 18px;
      text-align: center;
    }

    &__close{
      position: absolute;
      width: 10px;
      height: 10px;
      top: 20px;
      right: 20px;
      transition-duration: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        opacity: 0.5;
      }
    }

}