:root {
  --font-family: "CodecCold";
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white: #fff;
  --purple: #252160;
  --darkpurple: #0d0f38;
  --gray: #bbcbd4;
  --orange: #f79321;
  --darkorange: #d97427;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "CodecCold";
  src: url("../fonts/../fonts/codec-cold-regular.woff2") format("woff2");
  font-weight: 400;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "CodecCold";
  src: url("../fonts/../fonts/codec-cold-bold.woff2") format("woff2");
  font-weight: 600;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "CodecCold";
  src: url("../fonts/../fonts/codec-cold-heavy.woff2") format("woff2");
  font-weight: 800;
  font-display: block;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.select::after {
  content: "";
  width: 10px;
  height: 6px;
  background: url(/img/svg/arrow-down.svg) no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 15px;
  transition-duration: 0.1s;
}

.select__value {
  position: relative;
  user-select: none;
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.0666666667);
  border-radius: 5px;
  border: none;
  color: #fff;
  outline: none;
  padding: 15px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  transition-duration: 0.2s;
  cursor: pointer;
  z-index: 4;
}

.select__list {
  position: absolute;
  top: 5px;
  background: #fff;
  width: 100%;
  padding: 15px;
  padding-top: 38px;
  padding-bottom: 9px;
  overflow: auto;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  font-size: 14px;
  transition-duration: 0.2s;
  transform-origin: top;
  transform: scaleY(0.3);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
}

.select__list_open {
  transform: scaleY(1);
  opacity: 1;
  pointer-events: all;
}

.select:has(.select__list_open) .select__value {
  color: var(--darkpurple);
}

.select:has(.select__list_open)::after {
  filter: invert(1);
  transform: scaleY(-1);
}

.select__option {
  padding: 6px 0;
  cursor: pointer;
}

.select__option:hover {
  color: var(--purple);
}

.placeholder {
  position: relative;
  height: 100dvh;
  background: radial-gradient(56.33% 118.79% at 5.48% 1.77%, #262261 0%, #0D0F38 100%);
  padding: 0 50px;
}
@media (max-width: 576px) {
  .placeholder {
    padding: 0;
  }
}
.placeholder__background {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.placeholder__background-img {
  position: absolute;
  right: -5%;
  bottom: -5%;
  width: 110%;
  height: 110%;
  max-width: unset;
  transition: all 0.3s ease;
}
.placeholder__background-godzilla {
  object-position: 63% bottom;
}
@media (max-width: 844px) {
  .placeholder__background-godzilla {
    display: none;
  }
}
@media (max-width: 844px) {
  .placeholder__background-logo {
    opacity: 0.3;
    object-position: 70%;
    height: 80%;
  }
}
.placeholder__background-lightning {
  animation: lightning 15s ease infinite;
  opacity: 0;
}
@media (max-width: 844px) {
  .placeholder__background-lightning {
    display: none;
  }
}
.placeholder__inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: auto;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-left: 10%;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.placeholder__inner::-webkit-scrollbar {
  width: 0;
}
@media (max-height: 960px) {
  .placeholder__inner {
    display: block;
  }
}
@media (max-width: 1440px) {
  .placeholder__inner {
    margin-left: 5%;
  }
}
@media (max-width: 1350px) {
  .placeholder__inner {
    margin-left: 0;
  }
}
@media (max-width: 844px) {
  .placeholder__inner {
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .placeholder__inner {
    padding: 40px 16px;
  }
}
.placeholder__logo {
  width: 230px;
  height: 152px;
  margin-bottom: 25px;
}
.placeholder__h1 {
  font-size: 32px;
  font-weight: 800;
  color: #fff;
  margin: 0;
  margin-bottom: 15px;
  max-width: 610px;
}
.placeholder__text {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 1.1;
  margin-bottom: 10px;
  max-width: 610px;
}
.placeholder__form {
  margin-top: 40px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 580px;
}
@media (max-width: 576px) {
  .placeholder__form {
    margin-top: 30px;
  }
}
.placeholder__form-block {
  width: calc(50% - 7.5px);
}
@media (max-width: 576px) {
  .placeholder__form-block {
    width: 100%;
  }
}
.placeholder__form-block_margin {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .placeholder__form-block_margin {
    margin-bottom: -5px;
  }
}
.placeholder__form-block_wide {
  width: 100%;
}
.placeholder__form-block_center {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
}
@media (max-width: 576px) {
  .placeholder__form-block_center {
    width: 100%;
    margin-top: 5px;
  }
}
.placeholder__form-title {
  font-size: 14px;
  color: #fff;
}
.placeholder__form-title_bold {
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 5px;
}
.placeholder__form-input {
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.0666666667);
  border-radius: 10px;
  border: none;
  color: #fff;
  outline: none;
  padding: 15px;
  height: 44px;
  font-size: 14px;
  width: 100%;
  transition-duration: 0.5s;
}
.placeholder__form-input:hover {
  background: rgba(255, 255, 255, 0.2);
}
.placeholder__form-input:focus {
  color: var(--darkpurple);
  background: #fff;
}
.placeholder__form-textarea {
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.0666666667);
  border-radius: 10px;
  border: none;
  color: #fff;
  outline: none;
  padding: 15px;
  height: 88px;
  font-size: 14px;
  width: 100%;
  resize: none;
  transition-duration: 0.5s;
}
.placeholder__form-textarea:hover {
  background: rgba(255, 255, 255, 0.2);
}
.placeholder__form-textarea:focus {
  color: var(--darkpurple);
  background: #fff;
}
.placeholder__form-submit {
  color: var(--darkpurple);
  background: var(--orange);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 44px;
  transition-duration: 0.5s;
  cursor: pointer;
  font-weight: 600;
}
.placeholder__form-submit:hover {
  box-shadow: 6px 6px 0 0 var(--darkorange);
}

@keyframes lightning {
  0% {
    opacity: 0;
  }
  36% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  38% {
    opacity: 0;
  }
  39% {
    opacity: 1;
  }
  43% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5333333333);
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.3s;
}
.modal_open {
  z-index: 99;
  pointer-events: all;
  opacity: 1;
}
.modal__inner {
  background: #fff;
  width: 100%;
  max-width: 470px;
  padding: 60px 30px;
  border-radius: 10px;
  position: relative;
}
.modal__title {
  font-size: 32px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 5px;
}
.modal__text {
  font-size: 18px;
  text-align: center;
}
.modal__close {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 20px;
  right: 20px;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__close:hover {
  opacity: 0.5;
}