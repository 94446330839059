

.placeholder{
    position: relative;
    height: 100dvh;
    background: radial-gradient(56.33% 118.79% at 5.48% 1.77%, #262261 0%, #0D0F38 100%);
    padding: 0 50px;

    @media (max-width: 576px) {
      padding: 0;
    }

    &__background{
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__background-img{
        position: absolute;
        right: -5%;
        bottom: -5%;
        width: 110%;
        height: 110%;
        max-width: unset;
        transition: all 0.3s ease;
    }

    &__background-godzilla{
      object-position: 63% bottom;
      @media (max-width: 844px) {
        display: none;
      }
    }

    &__background-logo{
      @media (max-width: 844px) {
        opacity: 0.3;
        object-position: 70%;
        height: 80%;
      }
    }

    &__background-lightning{
      animation: lightning 15s ease infinite;
      opacity: 0;

      @media (max-width: 844px) {
        display: none;
      }
    }

    &__inner{
        position: relative;
        width: 100%;
        //background: #00000022;
        height: 100%;
        z-index: 2;
        overflow: auto;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: 10%;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0; }
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-height: 960px) {
          display: block;
        }
        @media (max-width: 1440px) {
          margin-left: 5%;
        }
        @media (max-width: 1350px) {
          margin-left: 0;
        }
        @media (max-width: 844px) {
            margin: 0 auto;
        }
        @media (max-width: 576px) {
          padding: 40px 16px;
        }
    }

    &__logo{
      width: 230px;
      height: 152px;
      margin-bottom: 25px;
    }

    &__h1{
      font-size: 32px;
      font-weight: 800;
      color: #fff;
      margin: 0;
      margin-bottom: 15px;
      max-width: 610px;
    }

    &__text{
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      line-height: 1.1;
      margin-bottom: 10px;
      max-width: 610px;
    }

    &__form{
        margin-top: 40px;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        width: 100%;
        max-width: 580px;
        @media (max-width: 576px) {
          margin-top: 30px;
        }
    }

    &__form-block{
        width: calc(50% - 7.5px);

        @media (max-width: 576px) {
            width: 100%;
        }

        &_margin{
            margin-bottom: 20px;
            @media (max-width: 576px) {
              margin-bottom: -5px;
            }
        }
        &_wide{
            width: 100%;
        }
        &_center{
            width: 50%;
            margin: 0 auto;
            margin-top: 10px;
            @media (max-width: 576px) {
                width: 100%;
                margin-top: 5px;
            }
        }

        
    }

    &__form-title{
        font-size: 14px;
        color: #fff;

        &_bold{
            font-weight: 800;
            font-size: 24px;
            margin-bottom: 5px;
        }
    }

    &__form-input{
        margin-top: 5px;
        background: #ffffff11;
        border-radius: 10px;
        border: none;
        color: #fff;
        outline: none;
        padding: 15px;
        height: 44px;
        font-size: 14px;
        width: 100%;
        transition-duration: 0.5s;

        &:hover{
            background: #ffffff33;
        }

        &:focus{
            color: var(--darkpurple);
            background: #fff;
        }
    }

    &__form-textarea{
        margin-top: 5px;
        background: #ffffff11;
        border-radius: 10px;
        border: none;
        color: #fff;
        outline: none;
        padding: 15px;
        height: 88px;
        font-size: 14px;
        width: 100%;
        resize: none;
        transition-duration: 0.5s;

        &:hover{
            background: #ffffff33;
        }

        &:focus{
            color: var(--darkpurple);
            background: #fff;
        }
    }

    &__form-submit{
        color: var(--darkpurple);
        background: var(--orange);
        border-radius: 5px;
        border: none;
        width: 100%;
        height: 44px;
        transition-duration: 0.5s;
        cursor: pointer;
        font-weight: 600;

        &:hover{
            box-shadow: 6px 6px 0 0 var(--darkorange);
        }
    }


}




@keyframes lightning {
  0% {
    opacity: 0;
  }
  36%{
    opacity: 0;
  }
  37%{
    opacity: 1;
  }
  38%{
    opacity: 0;
  }
  39%{
    opacity: 1;
  }
  43%{
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}